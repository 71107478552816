import {Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';
import {HttpHeaders} from '@angular/common/http';

@Injectable()
export class DataFetchService {
    constructor(private apollo: Apollo) {
    }

    fetchGraphQlResponse(query: any, isSearch = false): any {
        const queryStatement = gql`
            ${query}
        `;

        if (isSearch) {
            return this.apollo.use('endpoint2').watchQuery<any>({
                query: queryStatement,
            }).valueChanges;
        } else {
            return this.apollo.use('endpoint1').watchQuery<any>({
                query: queryStatement,
                errorPolicy: 'all',
                context: {
                    headers: new HttpHeaders().set(
                        'Authorization',
                        'JWT ' + localStorage.getItem('accessToken')
                    ).set('Authsession',localStorage.getItem('cookie')),
                },
            }).valueChanges;
        }
    }

    fetchGraphQLResponseForFileUpload(query: any, fileVariable: any): any{
        const gqlQuery = gql `${query}`;
        return this.apollo.use('endpoint1').mutate<any>({
            mutation: gqlQuery,
            context: {
                headers: new HttpHeaders()
                  .set('Authorization', 'JWT ' + localStorage.getItem('accessToken'))
                  .set('Authsession', localStorage.getItem('cookie')),
                useMultipart: true,
            },
            variables: {
                file: fileVariable,
            },
            fetchPolicy: 'no-cache',
        });

    }

    fetchBmcGraphQlResponse(query: any, isSearch = false): any {
        const queryStatement = gql`
            ${query}
        `;

        if (isSearch) {
            return this.apollo.use('endpoint3').watchQuery<any>({
                query: queryStatement,
            }).valueChanges;
        } else {
            return this.apollo.use('endpoint3').watchQuery<any>({
                query: queryStatement,
                errorPolicy: 'all',
                context: {
                    headers: new HttpHeaders().set(
                        'Authorization',
                        'JWT ' + localStorage.getItem('accessToken')
                    ).set('Authsession',localStorage.getItem('cookie')),
                },
            }).valueChanges;
        }
    }

    fetchBmcGraphQLResponseForFileUpload(query: any, fileVariable: any, datasetId: any): any{
        const gqlQuery = gql `${query}`;
        return this.apollo.use('endpoint3').mutate<any>({
            mutation: gqlQuery,
            context: {
                headers: new HttpHeaders()
                  .set('Authorization', 'JWT ' + localStorage.getItem('accessToken'))
                  .set('Authsession', localStorage.getItem('cookie')),
                useMultipart: true,
            },
            variables: {
                file: fileVariable,
                datasetId: datasetId
            },
            fetchPolicy: 'no-cache',
        });

    }
    

}
