import {Component, OnInit, OnDestroy} from '@angular/core';
import {NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {NgxSpinnerService} from "ngx-spinner";
import {SpinnerService} from '@shared/services/spinner.service'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  private spinnerSubscriber: any;
  public showHead: boolean = false;
  public showNotification = false;
  public notificationOption: object;

  constructor(
     private spinnerService: SpinnerService,
    private spinner: NgxSpinnerService,
    private router: Router,
  ) {
       /**
     * Route change detection for loader.
     */
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.spinnerService.tiggerSpinner = true;
      }

        if (event instanceof NavigationEnd) {
            this.spinnerService.tiggerSpinner = false;
        }

      if (event instanceof NavigationError) {
        this.spinnerService.tiggerSpinner = false;
      }
    });
  };

    ngOnInit() {
        this.spinnerSubscriber = this.spinnerService.showSpinner.subscribe(
          result => {
            result ? this.spinner.show() : this.spinner.hide();
            // if(result && JSON.parse(localStorage.getItem('userDetails')) == null){
            //   this.spinner.hide();
            // }
          }
        );
       
    }
    ngOnDestroy(){
      this.spinnerSubscriber.unsubscribe();
    }
}
