import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Suggestions } from '@app/landing/home/search/suggestions';
import { environment } from '@root/environments/environment';
import { SpinnerService } from '../../services/spinner.service';
import { DataFetchService } from '../../services/dataFetch.service';

export interface NavItem {
    label: string;
    route: string;
    disabled?: boolean;
}

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    @Output() onSearchClick: EventEmitter<any> = new EventEmitter();
    applicationName = 'Data Library';
    public productCode : string;

    public dashboardUrl: string = '/landing/dashboard'

    userName = localStorage.getItem('userType');
    navList: Array<NavItem> = [
        {
            label: 'Home',
            route: '/home',
        },
        {
            label: environment.vendorText+'s',
            route: '/vendor-list',
            disabled: false,
        },
        {
            label: 'Offerings',
            route: '/data-list',
            disabled: false,
        },
        {
            label: 'Assets',
            route: '/data-asset-list',
            disabled: false,
        },
        {
            label: 'Datasets',
            route: '/data-Set-List',
            disabled: false,
        },
        {
            label: '|',
            route: '/abc',
            disabled: true,
        },
        {
            label: 'Use Cases',
            route: '/use-case-list/use-case-intermidiate',
            disabled: false,
        },
        {
            label: 'Reports',
            route: '/reports-list',
            disabled: false,
        },
        {
            label: 'KPIs',
            route: '/kpi-list',
            disabled: false,
        },
        {
            label: 'Use Case to Data Mapping',
            route: '/data-mapping',
            disabled: false,
        },
        {
            label: '|',
            route: '/abc',
            disabled: true,
        },
        {
            label: 'Data Comparison',
            route: '/data-comparison',
            disabled: false,
        },      
        {
            label: '|',
            route: '/abc',
            disabled: true,
        },  
        {
            label: 'Requests',
            route: '/request-management',
            disabled: false,
        },
        {
            label: '|',
            route: '/abc',
            disabled: true,
        },
        {
            label: 'Glossary',
            route: '/glossary',
            disabled: false,
        },
        {
            label: 'Cart',
            route: '/my-cart',
            disabled: false,
        }
    ];

    tempList = [
        {
            label: 'Data Vendors',
            route: '/vendor-list',
            disabled: false,
        },
        {
            label: 'Data Offerings',
            route: '/data-list',
            disabled: false,
        },
    ];

    showSearch: boolean = true;
    notificationCount = 4;
    public searchList: any;
    public popularDataOffList: any;
    public addedDataAssets: any;
    public useCaseCategory: any;
    public userDetails: any;
    searchTerm = '';

    relatedTerms = [];

    dropdownItems = [];
    selectedItem: any;
    selectedCategory: any;
    errorMessage = 'Search for an Object';
    disableSearch = false;

    searchDataList = [
        {
            id: 1,
            label: 'Access Analytics',
        },
        {
            id: 2,
            label: 'Asset Analysis',
        },
        {
            id: 3,
            label: 'Change Healthcare',
        },
        {
            id: 4,
            label: 'Concerto Health AI',
        },
        {
            id: 5,
            label: 'Eudract',
        },
        {
            id: 6,
            label: 'Complaints Management',
        },
    ];

    dataOfferingsList = [
        {
            id: 1,
            label: 'Acorn AI',
        },
        {
            id: 2,
            label: 'Blueprint Genetics',
        },
        {
            id: 3,
            label: 'Aim',
        },
        {
            id: 4,
            label: 'Data Science as a Service',
        },
        {
            id: 5,
            label: 'Arcadia Analytics',
        },
        {
            id: 6,
            label: 'Sentinel',
        },
    ];

    addedDataAssetsList = [
        {
            id: 1,
            label: 'AHA Annual Survey',
        },
        {
            id: 2,
            label: 'Lens',
        },
        {
            id: 3,
            label: 'AHA IT Healthcare Healthcare',
        },
        {
            id: 4,
            label: 'Concerto Health AI',
        },
    ];

    useCaseCategoryList = [
        {
            id: 1,
            label: 'Access Analytics',
        },
        {
            id: 2,
            label: 'Alliances Analysis',
        },
        {
            id: 3,
            label: 'Asset Analysis',
        },
        {
            id: 4,
            label: 'Channel Analytics',
        },
    ];

    public showNotification: boolean = false;
    public notificationOption: any = {};

    public userType: string;

    constructor(
        private _router: Router,
        private spinnerService: SpinnerService,
        private dataFetchService: DataFetchService,
    ) {}

    ngOnInit() {
        this.userType = localStorage.getItem('userType');
        this.searchList = this.searchDataList;

        this.popularDataOffList = this.dataOfferingsList;

        this.addedDataAssets = this.addedDataAssetsList;

        this.useCaseCategory = this.useCaseCategoryList;
        this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
        this.productCode = environment.productCode;
    }

    /**
     * checking current activate route.
     * @param route route input.
     */
    isActiveRoute(route: string,label:string): boolean {
        try {
            return this._router.url.includes(route) || (this._router.url.includes("/use-case-list") && label == 'Use Cases');
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    /**
     * @param navItem route input.
     * @param index index of selected route.
     */
    navigateToRoute(navItem: NavItem, index: number) {
        try {
            if (!navItem.disabled) {
                if (
                    navItem.label == environment.vendorText+'s' ||
                    navItem.label == 'Datasets' ||
                    navItem.label == 'Offerings' ||
                    navItem.label == 'Home' ||
                    navItem.label == 'Assets' ||
                    navItem.label == 'Use Cases' || 
                    navItem.label == 'Reports' ||
                    navItem.label == 'KPIs' ||
                    navItem.label == 'Use Case to Data Mapping' || 
                    navItem.label == 'Data Comparison' || 
                    navItem.label == 'Requests' ||  
                    navItem.label == 'Glossary' ||  
                    navItem.label == 'Cart'
                ) {
                    // this._router.navigate(['/landing/' + navItem.route]).then(() => {
                    //     window.location.reload();
                    // });
                    this._router.navigate(['/landing/' + navItem.route])
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * Creating dropdown items on search
     */
    onSearch() {
        if (this.searchTerm) {
            this.dropdownItems = this.generateSuggestionList(this.searchTerm);
            this.validateSearchTerm(this.searchTerm);
            if (this.dropdownItems.length === 0) {
                this.errorMessage =
                    'No Results found, Please refine your search.';
            }
        } else {
            this.errorMessage = 'Search for an Object';
            this.dropdownItems = [];
        }
    }

    validateSearchTerm(searchTerm: string) {
        const terms = searchTerm.split('+');
        if (
            terms.length !== 3 ||
            (terms.length === 3 && terms.some((item) => item === ''))
        ) {
            this.disableSearch = true;
            this.selectedItem = undefined;
        } else {
            this.selectedItem = {};
            this.selectedItem['objectType'] = terms[0]
                .split(',')
                .map((item) => item.trim())
                .toString();
            this.selectedItem['diseaseIndication'] = terms[1]
                .split(',')
                .map((item) => item.trim())
                .toString();
            this.selectedItem['tag'] = terms[2]
                .split(',')
                .map((item) => item.trim())
                .toString();
        }
    }

    navigateToSearchResults() {
        // this.clearSearch();
        //this.onSearchClick.emit(this.selectedItem);
        let finalCategoryList = this.selectedCategory;
        if (
            finalCategoryList == undefined ||
            finalCategoryList == 'all' ||
            finalCategoryList == ''
        ) {
            finalCategoryList = [
                'Data Asset',
                'Data Vendor',
                'Data Offering',
                'Data Set',
            ];
        }
        this._router.navigate(['/landing/home/search-results'], {
            queryParams: {
                searchValue: this.selectedItem,
                category: finalCategoryList,
            },
        });
    }

    clearSearch() {
        this.searchTerm = '';
        this.dropdownItems = [];
    }

    generateSuggestionList(searchTerm: string): Array<any> {
        const termSet = this.splitSearchTerm(searchTerm);
        const finalSuggestionList = [];
        if (termSet.status) {
            const resultSet = {
                objectType: [],
                diseaseIndication: [],
                tag: [],
                orderList: [],
            };
            termSet.terms.map((term) => {
                this.fetchAssociatedSuggestion(term, resultSet);
            });

            if (resultSet.orderList.length === 0) {
                return finalSuggestionList;
            } else {
                Suggestions.KEYS.map((key) => {
                    if (resultSet.orderList.indexOf(key) === -1) {
                        resultSet.orderList.unshift(key);
                    }
                });
                Suggestions.KEYS.map((key) => {
                    if (resultSet[key].length === 0) {
                        resultSet[key] = Suggestions.KEY_WORDS[key];
                    }
                });
                for (const item_1 of resultSet[resultSet.orderList[0]]) {
                    for (const item_2 of resultSet[resultSet.orderList[1]]) {
                        for (const item_3 of resultSet[
                            resultSet.orderList[2]
                        ]) {
                            let item = {};
                            item[resultSet.orderList[0]] = item_1;
                            item[resultSet.orderList[1]] = item_2;
                            item[resultSet.orderList[2]] = item_3;
                            finalSuggestionList.push(item);
                            if (finalSuggestionList.length >= 5) {
                                return finalSuggestionList;
                            }
                        }
                    }
                }
                return finalSuggestionList;
            }
        } else {
            return finalSuggestionList;
        }
    }

    fetchAssociatedSuggestion(term, resultSet) {
        for (const suggestionKey of Suggestions.KEYS) {
            if (!resultSet[suggestionKey].length) {
                for (const item of Suggestions.KEY_WORDS[suggestionKey]) {
                    if (this.compareItemWithSuggestion(term, item)) {
                        resultSet[suggestionKey].push(item);
                    }
                }
                if (resultSet[suggestionKey].length) {
                    resultSet.orderList.unshift(suggestionKey);
                    return;
                }
            }
        }
    }

    compareItemWithSuggestion(term, suggestion): boolean {
        const termList = term.split(',').filter((item) => item !== '');
        for (let _term of termList) {
            _term = _term.toLowerCase() === 'all' ? '' : _term;
            if (suggestion.toLowerCase().includes(_term.toLowerCase())) {
                return true;
            }
        }
        return false;
    }

    splitSearchTerm(searchTerm: string) {
        const termSet = {
            status: false,
            terms: [],
        };
        try {
            let tempTerms = [];
            tempTerms = searchTerm.split('+');
            if (tempTerms.length > 3 || tempTerms.length === 0) {
                return termSet;
            }
            tempTerms.filter((term, index) => {
                if (term.trim()) {
                    termSet.terms.push(term.trim());
                }
            });

            if (termSet.terms.length === 0) {
                return termSet;
            } else {
                termSet.status = true;
                return termSet;
            }
        } catch (e) {
            console.log(e);
            return termSet;
        }
    }

    searchButtonClick() {
        this.navigateToSearchResults();
        // if (this.selectedItem) {
        //     this.navigateToSearchResults();
        // }
    }

    /**
     * setting focus to input search.
     */
    setFocus() {
        setTimeout(() => {
            //this.searchElement.nativeElement.focus();
        });
    }
    logOut(){
        this.logoutAPI();
        this._router.navigate(['/login']);
        localStorage.clear();
    }

    logoutAPI(){
        this.spinnerService.tiggerSpinner = true;
        const query = `{
            logoutApplication(user:"${this.userType}"){
              status
            }
        }`;
        this.dataFetchService.fetchGraphQlResponse(query).subscribe((response) => {
            this.errorHandler(response);            
            if(response.data.logoutApplication.status.toLowerCase() === 'success'){
                this.spinnerService.tiggerSpinner = false;
            }
        },(err) => {
            this.spinnerService.tiggerSpinner = false;
            this.notificationOption = {
                message: 'Something went wrong!!',
                type: 'error',
                time: 5000,
            };
            this.showNotification = true;
        });
    }

    errorHandler(response){
        if (response.hasOwnProperty('errors')) {
            if ( response['errors'][0]['message'] == 'authentication failed' ) {
                this._router.navigateByUrl('/login');
            } else {
                this.spinnerService.tiggerSpinner = false;
                this.notificationOption = {
                    message: 'Something went wrong!!',
                    type: 'error',
                    time: 5000,
                };
                this.showNotification = true;
            }
        }
    }

}
