import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './login/login.component';
import { AuthGuardGuard } from 'src/app/shared/services/auth-guard.guard'


const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'landing',
        canActivate: [AuthGuardGuard],
        loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule)
    },
    
    {
        path: '**',
        redirectTo: 'landing',
        pathMatch: 'full'
    }

];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
