<div>
    <app-header></app-header>
    <div class="main-container">
        <div class="sub-container">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

<ng-template #keepMeLoggedIn let-a="close" let-close="dismiss">
    <div class="modal-header">
        <h5 class="modal-title">Session Expiry Warning!</h5>
        <!-- <button type="button" class="close" aria-label="Close" (click)="close('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button> -->
    </div>
    <div class="modal-body">
        <div class="col-12">
            Your session is about to expire. Do you want to stay logged in?
        </div>        
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark mr-2" (click)="logout()">Logout</button>
        <button type="button" class="btn btn-info btn-chat" (click)="keepMeLoggedInCheck()"> Keep me logged in </button>
    </div>
</ng-template>

<ng-template #sessionExpiredHardAlert let-a="close" let-close="dismiss">
    <div class="modal-header">
        <h5 class="modal-title">Session Expired!</h5>
    </div>
    <div class="modal-body">
        <div class="col-12">
            Your session has expired. Please log in again to continue.
        </div>        
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-info btn-chat" (click)="logout()"> Ok </button>
    </div>
</ng-template>