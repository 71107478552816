import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DataFetchService} from '@shared/services/dataFetch.service';
import {SpinnerService} from '@shared/services/spinner.service';
import {environment} from '@root/environments/environment';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    applicationName = 'Data Library';
    headerUserName = 'John Doe';
    notificationCount = 4;
    showSearch = true;

    userName = '';
    password = '';
    errorMsg = false;
    wrngMsg = false;
    public productCode : string;

    constructor(
        private router: Router,
        private dataFetchService: DataFetchService,
        private spinnerService: SpinnerService,
        private activatedRoute: ActivatedRoute
    ) {
    }

    ngOnInit() {
        localStorage.clear();
        this.productCode = environment.productCode;
    }

    login() {
        if (this.userName && this.password) {
            if (environment.production) {
                this.loginGql();
            } else {
                if (
                    (this.userName == 'DemoClassified' &&
                        this.password == 'cls@123') ||
                    (this.userName == 'DemoPublic' &&
                        this.password == 'pub@123') ||
                    (this.userName == 'Admin' && this.password == 'admin@123')
                ) {
                    localStorage.setItem('userType', this.userName);
                    this.router.navigateByUrl('/landing');
                } else {
                    this.wrngMsg = true;
                }
            }
        } else {
            this.errorMsg = true;
        }
    }

    loginGql() {
        this.spinnerService.tiggerSpinner = true;
        const query = `
                       mutation {
                            auth(username: "${this.userName}", password: "${this.password}") {
                                accessToken,
                                refreshToken,
                                cookie,
                                userProfile{
                                    name,
                                    email,
                                    userId,
                                    userName,
                                    groups{
                                      key,
                                      value
                                    }
                                  }
                                }
                        }`;
        localStorage.setItem(
            'cookie', '!@#$%^&*QWE'
        );
        this.dataFetchService.fetchGraphQlResponse(query).subscribe(
            (response) => {
                localStorage.setItem('userType', this.userName);
                if (response.data.auth !== null && response.data.auth.accessToken) {
                    let userDetails = {'name': response.data.auth.userProfile[0]['name'],'email':response.data.auth.userProfile[0]['email'],'role':response.data.auth.userProfile[0]['groups'][0]['value']}
                    localStorage.setItem(
                        'accessToken',
                        response.data.auth.accessToken
                    );
                    localStorage.setItem(
                        'refreshToken',
                        response.data.auth.refreshToken
                    );
                    localStorage.setItem(
                        'cookie',
                        response.data.auth.cookie
                    );
                     localStorage.setItem(
                        'userDetails',
                          JSON.stringify(userDetails)
                    );
                    this.router.navigate(['landing'], {relativeTo: this.activatedRoute});
                } else {
                    this.wrngMsg = true;
                    this.spinnerService.tiggerSpinner = false;
                }

            },
            (err) => {
                this.spinnerService.tiggerSpinner = false;
                this.errorMsg = true;
            }
        );
    }
}
