<div class="header-container">
    <div class="top-section d-flex justify-content-between">
        <div class="d-flex align-items-center">
            <h4 class="mb-0 text-white">
                {{productCode | uppercase}} {{applicationName}}
            </h4>
        </div>
    </div>
</div>
<div class="formDiv">
    <div class="formContainer">
        <h4 style="text-align: center; margin-bottom: 25px;">Login</h4>
        <p class="errorText" *ngIf="errorMsg">Please enter username and password</p>
        <p class="errorText" *ngIf="wrngMsg">You have entered wrong username or password!!</p>
        <form>
            <div class="input-field">
                <label>User Name</label>
                <input type="text" [(ngModel)]="userName"  [ngModelOptions]="{standalone: true}">
            </div>

            <div class="input-field">
                <label>Password</label>
                <input type="password" [(ngModel)]="password" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="loginBtn">
                <button type="Submit" class="btn btn-primary ml-2" (click)="login()">Login</button>
            </div>

        </form>
    </div>
</div>
