import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {Apollo, ApolloModule} from 'apollo-angular';
import {HttpLink, HttpLinkModule} from 'apollo-angular-link-http';
import {InMemoryCache} from "apollo-cache-inmemory";
import {environment} from "@root/environments/environment";
import {DefaultOptions} from "apollo-client";


@NgModule({
    exports: [
        HttpClientModule,
        ApolloModule,
        HttpLinkModule
    ]
})
export class GraphQLModule {
    constructor(
        apollo: Apollo,
        httpLink: HttpLink
    ) {

        const defaultOptions: DefaultOptions = {
            watchQuery: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'ignore',
            },
            query: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
            },
        }
        // create Apollo
        const options1: any = { uri: environment.graphQLUri };
        apollo.createNamed('endpoint1',{
            link: httpLink.create(options1),
            cache: new InMemoryCache(),
            defaultOptions: defaultOptions
        });
        const options2: any = { uri: environment.graphQLSearchUri };
        apollo.createNamed('endpoint2',{
            link: httpLink.create(options2),
            cache: new InMemoryCache(),
            defaultOptions: defaultOptions
        });
        const options3: any = { uri: environment.graphQLUriBmc };
        apollo.createNamed('endpoint3',{
            link: httpLink.create(options3),
            cache: new InMemoryCache(),
            defaultOptions: defaultOptions
        });
    }
}
