import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LandingComponent } from './landing/landing.component';
import { HeaderModule } from './shared/components/header/header.module';
import { GraphQLModule } from '@shared/services/graphql.module';
import {DataFetchService} from "@shared/services/dataFetch.service";
import { FormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import {CommonModule} from '@angular/common';
import { Filters1Component } from './shared/components/filters1/filters1.component';

@NgModule({
    declarations: [AppComponent, LandingComponent, LoginComponent, Filters1Component],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgbModule,
        HeaderModule,
        GraphQLModule,
        FormsModule,
        NgxSpinnerModule,
        BrowserAnimationsModule,
        CommonModule
    ],
    providers: [DataFetchService, DatePipe],
    exports: [],
    bootstrap: [AppComponent],
})
export class AppModule {}
