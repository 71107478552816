import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { DataFetchService } from '@shared/services/dataFetch.service';
import { SpinnerService } from '../shared/services/spinner.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit, OnDestroy {    
    
    public heartBeatTime: number = 1 * 60 * 1000 //  1 min * 60 secs * 1000 milliseconds = 1 min in milliseconds
    public idleTime: number = 20 * 60 * 1000  // 20 min * 60 secs * 1000 milliseconds = 20 mins in milliseconds -> idle time after which the trigger will happen
    public modalOpenCheckTime: number = 5 * 60 * 1000 // 5min * 60 secs * 1000 millisecons = 5 mins in milliseconds    
        
    public userInactive: Subject<any> = new Subject();

    public idleTimeController;
    public modalOpenCheckController;
    public heartBeatIntervalController;

    public isModalOpen = false;
    public modalOptions: NgbModalOptions = {
        size: 'lg',
        windowClass: 'standard-modal-dqm',
        backdrop: 'static',
        centered: true
    };

    @HostListener('window:mousemove') refreshUserStateMouse() {
        clearTimeout(this.idleTimeController);
        this.idleRefresh();
    }

    @HostListener('window:keyup', ['$event']) 
    refreshUserStateKey(event: KeyboardEvent) {
        clearTimeout(this.idleTimeController);
        this.idleRefresh();
    }

    @ViewChild('keepMeLoggedIn') keepMeLoggedIn;
    @ViewChild('sessionExpiredHardAlert') sessionExpiredHardAlert;

    public showNotification: boolean = false;
    public notificationOption: any = {};

    public userType: string;

    constructor(private modalService: NgbModal,
        private dataFetchService: DataFetchService,
        private spinnerService: SpinnerService,
        private router: Router,
        private activatedRoute: ActivatedRoute){
        }

    ngOnInit(): void {        

        this.clearTimeoutsAndIntervals();

        this.heartBeatIntervalController = setInterval(
            ()=>{
                this.heartBeat();
            }, 
            this.heartBeatTime
        );        
         
        clearTimeout(this.idleTimeController);
        this.idleRefresh(); 

        this.userInactive.subscribe(() => { //KeepMeLoggedIn check when user is inactive
            clearTimeout(this.modalOpenCheckController)
            this.isModalOpen = true;
            this.modalService.open(this.keepMeLoggedIn, this.modalOptions);
            // logout happens if user doesnt select anything until modalOpenCheckTime - 5 minutes
            this.modalOpenCheckController = setTimeout(()=>this.isModalOpen===true? this.logout() : '', this.modalOpenCheckTime) 
        });


    }

    ngOnDestroy(){
        this.clearTimeoutsAndIntervals();
    }

    heartBeat(){
        const query = `{
            heartbeat{
              sessionStatus
            }
          }`
          this.dataFetchService.fetchGraphQlResponse(query).subscribe(
            (response) => {
                response = response.data.heartbeat.sessionStatus
                if(response.toLowerCase() !== 'success'){
                    this.modalService.open(this.sessionExpiredHardAlert, this.modalOptions);
                    clearInterval(this.heartBeatIntervalController);
                    clearInterval(this.idleTimeController);
                    this.modalOpenCheckController = setTimeout(()=>this.isModalOpen===true? this.logout() : '', this.modalOpenCheckTime) 
                }
            },
            (err) => {
                this.modalService.open(this.sessionExpiredHardAlert, this.modalOptions);
                clearInterval(this.heartBeatIntervalController);
                clearInterval(this.idleTimeController);
                this.modalOpenCheckController = setTimeout(()=>this.isModalOpen===true? this.logout() : '', this.modalOpenCheckTime) 
            }
        );
    }

    clearTimeoutsAndIntervals(){
        this.isModalOpen = false;
        clearInterval(this.heartBeatIntervalController);
        clearInterval(this.idleTimeController);
        clearTimeout(this.modalOpenCheckController);
        this.modalService.dismissAll();
    }

    idleRefresh() {
        if(!this.isModalOpen){
            clearTimeout(this.idleTimeController);
            this.idleTimeController = setTimeout(() => this.userInactive.next(undefined), this.idleTime);           
            localStorage.setItem('idleTimeControllerId', this.idleTimeController);
        }
    }

    keepMeLoggedInCheck() {
        this.modalService.dismissAll();
        this.isModalOpen = false;

        this.triggerRefreshAPI();
    }

    triggerRefreshAPI(){
        this.spinnerService.tiggerSpinner = true;
        let refreshToken = localStorage.getItem('refreshToken');
        const query = `
            mutation {
                refresh(refreshToken: "${refreshToken}") {
                    newToken
                    sessionStatus
                }
            }
        `;        
        this.dataFetchService.fetchGraphQlResponse(query).subscribe(
            (response) => {
                if (response.data.refresh.sessionStatus == 'SUCCESS') {
                    this.spinnerService.tiggerSpinner = false;
                    localStorage.setItem('accessToken', response.data.refresh.newToken);
                    clearTimeout(this.idleTimeController);                    
                    this.idleRefresh();
                } else {
                    this.spinnerService.tiggerSpinner = false;
                    this.modalService.dismissAll();
                    this.modalService.open(this.sessionExpiredHardAlert, this.modalOptions);
                }
            },
            (err) => {
                this.spinnerService.tiggerSpinner = false;
                this.modalService.dismissAll();
                this.modalService.open(this.sessionExpiredHardAlert, this.modalOptions);
                clearInterval(this.heartBeatIntervalController);
                
            }
        );

    }

    logout() {
        this.logoutAPI();
        this.clearTimeoutsAndIntervals();
        localStorage.clear();
        this.router.navigate(['../login'], { relativeTo: this.activatedRoute });
    }

    logoutAPI(){
        this.spinnerService.tiggerSpinner = true;
        const query = `{
            logoutApplication(user:"${this.userType}"){
              status
            }
        }`;
        this.dataFetchService.fetchGraphQlResponse(query).subscribe((response) => {
            this.errorHandler(response);            
            if(response.data.logoutApplication.status.toLowerCase() === 'success'){
                this.spinnerService.tiggerSpinner = false;
            }
        },(err) => {
            this.spinnerService.tiggerSpinner = false;
            this.notificationOption = {
                message: 'Something went wrong!!',
                type: 'error',
                time: 5000,
            };
            this.showNotification = true;
        });
    }

    errorHandler(response){
        if (response.hasOwnProperty('errors')) {
            if ( response['errors'][0]['message'] == 'authentication failed' ) {
                this.router.navigateByUrl('/login');
            } else {
                this.spinnerService.tiggerSpinner = false;
                this.notificationOption = {
                    message: 'Something went wrong!!',
                    type: 'error',
                    time: 5000,
                };
                this.showNotification = true;
            }
        }
    }

}
