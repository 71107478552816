import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-filters1',
  templateUrl: './filters1.component.html',
  styleUrls: ['./filters1.component.scss']
})
export class Filters1Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
