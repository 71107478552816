<div class="header-container">
    <div class="top-section d-flex col-12">
        <div class="align-items-center col-4">
            <div class="mb-0 {{productCode}}-main">
                <img src="assets/icons/{{productCode}}/Nav.svg" class="appImageLib" *ngIf="productCode == 'zs'"/>
                <span class="websiteHeader">
                    <span *ngIf="productCode == 'zs'">{{ productCode | uppercase }}</span> {{ applicationName }}
                </span>
            </div>
        </div>
        <div class="search-container col-7 text-right">
            <li class="nav-item nav-dashboard">
                <a class="nav-link pr-0" style="cursor: pointer; color: white;" [routerLink]="[dashboardUrl]"><img src="assets/icons/Union.svg" class="appImageLib"
                                              style="margin-right: 5px;"/>Dashboard</a>
            </li>
        </div>
        <div class="d-flex align-items-center right-panel justify-content-between">

            <div ngbDropdown>
                <button class="btn" id="gfg"
                        ngbDropdownToggle><img class="user-icon" src="assets/icons/user_icon.svg">
                </button>
                <div ngbDropdownMenu="gfg">
                    <button ngbDropdownItem>{{userDetails?.name}}</button>
                    <button ngbDropdownItem>{{userDetails?.email}}</button>
                     <button ngbDropdownItem>{{userDetails?.role}}</button>
                    <button ngbDropdownItem (click)="logOut()">Logout</button>
                </div>
            </div>
               <img class="question" src="assets/icons/question.svg">
        </div>

    </div>
    <div class="nav-section">
        <div class="nav-container">
            <ul class="nav">
                <div style="margin-left: 1rem; display: inline-flex;">
                    <ng-container *ngFor="let navItem of navList; let _i = index">

                        <li class="nav-item" [ngClass]="[navItem.label == 'Cart' ? 'nav-cart' : '']">
                            <a class="nav-link" href="javascript:void(0)"
                               [ngClass]="{ active: isActiveRoute(navItem.route,navItem.label),disabled: navItem.disabled }"
                               (click)="navigateToRoute(navItem, _i)">
                                <em class="cart-icon" *ngIf="navItem.label == 'Cart'"></em>
                                <span
                                    [ngClass]="[navItem.label == 'Cart' ? 'nav-cart-text' : '']">{{ navItem.label }}</span>
                            </a>
                        </li>
                    </ng-container>
                </div>
            </ul>
        </div>
    </div>
</div>
